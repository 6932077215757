<template>
  <div>
    <div class="container">
      <!-- Vehicles -->
      <div class="row g-0">
        <div class="col-12">
          <h1
            class="display-5 mt-5 mb-3 d-flex justify-content-center align-items-center"
          >
            <span class="highlighted me-auto">My Vehicles.</span>
            <button
              class="btn btn-primary mt-auto me-2"
              data-bs-toggle="offcanvas"
              data-bs-target="#vehiclesOffcanvas"
            >
              Filters
            </button>

            <input
              type="text"
              class="form-control w-auto mt-auto d-none d-md-block"
              placeholder="Search by Reservation, Customer, or Vehicle"
              v-model="searchQuery"
            />
          </h1>
          <label class="d-md-none">Search:</label>
          <input
            type="text"
            class="form-control w-100 d-md-none mb-3"
            placeholder="Search by Reservation, Customer, or Vehicle"
            v-model="searchQuery"
          />
          <p v-if="!vehicles.length">
            Looks like you don't have access to any vehicles! Contact your
            supervisor to get access.
          </p>
        </div>
      </div>
    </div>
    <div class="fluid-container">
      <!-- Reservations Table -->
      <div class="row g-0">
        <div class="col-12 mb-5" v-if="vehicles.length">
          <div
            class="table-responsive"
            style="min-height: 65vh !important; max-height: 65vh !important"
          >
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Vehicle</th>
                  <th>Current Reservation</th>
                  <th>Next Reservation</th>
                  <th>Reservation Notes</th>
                  <th>Vehicle Notes</th>
                  <th>Location</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody
                v-for="platform in ['ALL', 'TURO', 'HQ']"
                :key="'vehicles-row-platform-' + platform"
              >
                <tr style="background: rgb(0, 0, 255) !important">
                  <td
                    style="box-shadow: unset !important"
                    colspan="7"
                    class="align-middle"
                  >
                    <div class="d-flex">
                      <span
                        class="fw-bold my-auto"
                        style="color: white !important"
                        >Platform:
                        <span class="badge badge-white">{{ platform }}</span>
                      </span>
                      <button
                        class="btn btn-white mt-0 mb-0 fw-bold my-auto ms-auto"
                        data-bs-toggle="collapse"
                        :data-bs-target="
                          '#vehicles-tbody-' +
                          $store.state.region +
                          '-' +
                          platform
                        "
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                          fill="black"
                        >
                          <path
                            d="M13.9999 4.99989L5.00001 4.99976L4.99998 6.99976L11.9999 6.99986L12 14.5859H6.5858L13 21.0001L19.4142 14.5859L14 14.5859L13.9999 4.99989Z"
                          ></path>
                        </svg>
                      </button>
                    </div>
                  </td>
                </tr>
                <tr
                  v-for="vehicle in sortedVehicles.filter((v) => {
                    return v.platform == platform;
                  })"
                  :key="'vehicle-' + vehicle.vin"
                  :id="'vehicles-tbody-' + $store.state.region + '-' + platform"
                >
                  <td class="border align-middle">
                    <div class="card-body">
                      <div class="vehicle-card d-flex align-items-center">
                        <div class="d-flex flex-grow-1 align-items-center">
                          <span class="align-items-center flex-grow-1">
                            <h5
                              class="mb-0 clickable"
                              @click="copyToClipboard(vehicle.vin)"
                            >
                              {{ vehicle.model }} ({{ vehicle.vin.slice(-6) }})
                            </h5>
                            <p class="mt-1 mb-0">
                              <span class="badge badge-tertiary me-1">
                                {{ vehicle.platform }}
                              </span>
                              <span
                                class="badge badge-secondary me-1 clickable"
                                @click="copyToClipboard(vehicle.license_plate)"
                              >
                                {{ vehicle.license_plate }}
                              </span>
                              <span class="badge badge-tertiary me-1">
                                {{ vehicle.year }}
                              </span>
                              <span class="badge badge-tertiary me-1">
                                <span
                                  class="color-dot"
                                  :style="{
                                    'background-color':
                                      vehicle.color.toLowerCase() +
                                      ' !important',
                                  }"
                                ></span>
                              </span>
                              <span class="badge me-1">
                                {{ getRegionPrefix(vehicle.region_id) }}
                              </span>
                              <span class="badge badge-tertiary">
                                <!-- Status Text -->
                                {{ vehicle.status }}
                              </span>
                            </p>
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>

                  <td
                    class="border"
                    style="min-width: 35% !important"
                    v-if="getCurrentReservation(vehicle).current"
                  >
                    <div
                      class="d-flex justify-content-between align-items-center w-100"
                    >
                      <div class="flex-grow-1">
                        {{
                          getCurrentReservation(vehicle).current
                            .customer_first_name
                        }}
                        {{
                          getCurrentReservation(vehicle).current
                            .customer_last_name
                        }}
                        <br />
                        {{ getCurrentReservation(vehicle).current.platform_id }}
                        <span
                          class="badge"
                          :class="{
                            'badge-red':
                              getCurrentReservation(vehicle).current
                                .payment_balance > 0,
                            'badge-tertiary':
                              getCurrentReservation(vehicle).current
                                .payment_balance <= 0,
                          }"
                          >{{
                            new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(
                              getCurrentReservation(vehicle).current
                                .payment_balance
                            )
                          }}</span
                        >
                        <br />
                        {{
                          new Date(
                            getCurrentReservation(vehicle).current.pickup_at
                          ).toLocaleDateString("en-US", {
                            timeZone:
                              getCurrentReservation(vehicle).current
                                .pickup_timezone,
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                          })
                        }}
                        →
                        {{
                          new Date(
                            getCurrentReservation(vehicle).current.return_at
                          ).toLocaleDateString("en-US", {
                            timeZone:
                              getCurrentReservation(vehicle).current
                                .return_timezone,
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                          })
                        }}
                      </div>
                      <button
                        @click="id = getCurrentReservation(vehicle).current.id"
                        tag="button"
                        class="btn btn-sm btn-primary ms-1"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#reservationOffcanvas-vehicles"
                      >
                        View
                      </button>
                    </div>
                  </td>

                  <td
                    class="text-center border"
                    style="min-width: 35% !important"
                    v-if="!getCurrentReservation(vehicle).current"
                  ></td>

                  <td
                    class="border"
                    style="min-width: 35% !important"
                    v-if="getCurrentReservation(vehicle).next"
                  >
                    <div
                      class="d-flex justify-content-between align-items-center w-100"
                    >
                      <div class="flex-grow-1">
                        {{
                          getCurrentReservation(vehicle).next
                            .customer_first_name
                        }}
                        {{
                          getCurrentReservation(vehicle).next.customer_last_name
                        }}

                        <br />
                        {{ getCurrentReservation(vehicle).next.platform_id }}
                        <span class="badge badge-tertiary">{{
                          new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(
                            getCurrentReservation(vehicle).next.payment_balance
                          )
                        }}</span>
                        <br />
                        {{
                          new Date(
                            getCurrentReservation(vehicle).next.pickup_at
                          ).toLocaleDateString("en-US", {
                            timeZone:
                              getCurrentReservation(vehicle).next
                                .pickup_timezone,
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                          })
                        }}
                        →
                        {{
                          new Date(
                            getCurrentReservation(vehicle).next.return_at
                          ).toLocaleDateString("en-US", {
                            timeZone:
                              getCurrentReservation(vehicle).next
                                .return_timezone,
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                          })
                        }}
                      </div>
                      <button
                        @click="id = getCurrentReservation(vehicle).next.id"
                        tag="button"
                        class="btn btn-sm btn-primary ms-1"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#reservationOffcanvas-vehicles"
                      >
                        View
                      </button>
                    </div>
                  </td>

                  <td
                    class="text-center border"
                    style="min-width: 35% !important"
                    v-if="!getCurrentReservation(vehicle).next"
                  ></td>

                  <td class="border align-middle" style="min-width: 250px">
                    <textarea
                      class="form-control"
                      @input="
                        onReservationNotesChange(
                          $event,
                          getCurrentReservation(vehicle).current.id
                        )
                      "
                      :value="getCurrentReservation(vehicle).current.notes"
                      v-if="getCurrentReservation(vehicle).current"
                    ></textarea>
                  </td>

                  <td class="border align-middle" style="min-width: 250px">
                    <textarea
                      class="form-control"
                      @input="onNotesChange($event, vehicle)"
                      :value="vehicle.notes"
                    ></textarea>
                  </td>

                  <td class="border align-middle" style="min-width: 250px">
                    <select
                      class="form-control form-select w-100"
                      :value="vehicle.location_id"
                      @change="onLocationChange($event, vehicle)"
                    >
                      <option
                        :value="location.id"
                        v-for="location in locations(vehicle)"
                        :key="
                          'location-select-' + vehicle.vin + '-' + location.id
                        "
                      >
                        {{ location.name }}
                      </option>
                    </select>
                  </td>

                  <td class="align-middle border">
                    <div
                      class="btn-group btn-group-sm align-middle"
                      role="group"
                      aria-label="Button group with nested dropdown"
                    >
                      <div class="btn-group" role="group">
                        <button
                          type="button"
                          class="btn btn-primary btn-sm dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Action
                        </button>
                        <ul class="dropdown-menu">
                          <li>
                            <a
                              class="dropdown-item"
                              @click="updateStatus(vehicle, 'Ready')"
                              >Mark as Ready</a
                            >
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              @click="updateStatus(vehicle, 'On Rent')"
                              >Mark as On Rent</a
                            >
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              @click="updateStatus(vehicle, 'Dirty')"
                              >Mark as Dirty</a
                            >
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              @click="updateStatus(vehicle, 'Damaged')"
                              >Mark as Damaged</a
                            >
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              @click="updateStatus(vehicle, 'Repairing')"
                              >Mark as Out for Repair</a
                            >
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              @click="updateStatus(vehicle, 'Unavailable')"
                              >Mark as Unavailable</a
                            >
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              @click="updateStatus(vehicle, 'Employee')"
                              v-if="vehicle.status != 'Employee'"
                              >Transfer to Employee</a
                            >
                            <a
                              class="dropdown-item"
                              @click="updateStatus(vehicle, 'Dirty')"
                              v-else
                              >Transfer to Fleet</a
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td
                    :colspan="7"
                    :id="
                      'vehicles-tbody-' + $store.state.region + '-' + platform
                    "
                    v-if="
                      sortedVehicles.filter((v) => {
                        return v.platform == platform;
                      }) == 0 ||
                      !sortedVehicles.filter((v) => {
                        return v.platform == platform;
                      })
                    "
                  >
                    No results found.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- Reservation View -->
      <div
        class="offcanvas offcanvas-start"
        tabindex="-1"
        id="reservationOffcanvas-vehicles"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div class="offcanvas-header align-middle">
          <h3 class="highlighted my-0 mt-0 fw-bold">View Reservation</h3>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            Close
          </button>
        </div>
        <div class="offcanvas-body">
          <ReservationOffcanvas :id="id"></ReservationOffcanvas>
        </div>
      </div>
      <!-- Filters -->
      <div
        class="offcanvas offcanvas-start"
        tabindex="-1"
        id="vehiclesOffcanvas"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div class="offcanvas-header align-middle">
          <h3 class="highlighted my-0 mt-0 fw-bold">Filters</h3>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            Close
          </button>
        </div>
        <div class="offcanvas-body">
          <h6 class="mt-3">Search By</h6>
          <!-- Search by Reservation, Customer, or Vehicle -->
          <label class="mt-3">Keywords</label>
          <input
            type="text"
            class="form-control w-100 mb-3"
            placeholder="Reservation, Customer, or Vehicle"
            v-model="searchQuery"
          />

          <div class="mt-3 mb-3"></div>
          <h6 class="mt-5">Sort By</h6>
          <label class="mt-3">Attribute</label>
          <!-- Sort By -->
          <select class="form-control form-select w-100" v-model="sorted">
            <option value="vin">Vin</option>
            <option value="model">Model</option>
            <option value="status">Status</option>
            <option value="region">Region</option>
            <option value="license_plate">License Plate</option>
            <option value="location_id">Location</option>
            <option value="region_id">Region</option>
            <option value="color">Color</option>
          </select>
          <label class="mt-3">Order</label>
          <!-- Sort By -->
          <select class="form-control form-select w-100 mb-3" v-model="order">
            <option value="desc">Descending</option>
            <option value="asc">Ascending</option>
          </select>

          <h6 class="mt-5">Filter By</h6>
          <!-- Filter by Status -->
          <label class="mt-3">Filter by Vehicle Status</label>
          <select class="form-control form-select w-100" v-model="status">
            <option value="">All</option>
            <option value="Dirty">Dirty</option>
            <option value="Return Photos">Return Photos</option>
            <option value="Cleaning">Cleaning</option>
            <option value="Pickup Photos">Pickup Photos</option>
            <option value="Ready">Ready</option>
            <option value="On Rent">On Rent</option>
            <option value="Damaged">Damaged</option>
            <option value="Repairing">Out for Repair</option>
            <option value="Unavailable">Unavailable</option>
            <option value="Employee">Employee</option>
          </select>
          <!-- Filter by Location -->
          <label class="mt-3">Filter by Location</label>
          <select
            class="form-control form-select w-100 mb-5"
            v-model="location"
          >
            <option value="">All</option>
            <option
              :value="location.id"
              v-for="location of $store.state.user.locations.filter((l) => {
                return l.region_id == $store.state.region;
              })"
              :key="'filter-vehicles-location-' + location.id"
            >
              {{ location.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReservationOffcanvas from "@/components/offcanvas/ReservationOffcanvas.vue";

export default {
  name: "VehiclesView",
  data: function () {
    return {
      searchQuery: "",
      status: "",
      sorted: "vin",
      order: "desc",
      region: "",
      location: "",
      id: "", //Reservation offcanvas
    };
  },
  components: {
    ReservationOffcanvas,
  },
  created: function () {
    this.debouncedUpdateNotes = this.lodash.debounce(this.updateNotes, 4000);
    this.debouncedUpdateReservationNotes = this.lodash.debounce(
      this.updateReservationNotes,
      4000
    );
    this.debouncedUpdateLocation = this.lodash.debounce(
      this.updateLocation,
      200
    );
  },
  methods: {
    redirect: function (reservation, index) {
      console.log(reservation[index]);
      window.open("/reservation/" + reservation[index].id.toString(), "_blank");
    },
    getRegionPrefix(regionId) {
      return (
        this.$store.state.user.regions.find((r) => r.id === regionId)?.prefix ||
        ""
      );
    },
    onNotesChange: function (event, vehicle) {
      const newNotes = event.target.value;
      this.debouncedUpdateNotes(vehicle, newNotes);
    },
    onReservationNotesChange: function (event, id) {
      const newNotes = event.target.value;
      this.debouncedUpdateReservationNotes(id, newNotes);
    },
    onLocationChange: function (event, vehicle) {
      const location = event.target.value;
      this.debouncedUpdateLocation(vehicle, location);
    },
    updateNotes: function (vehicle, notes) {
      this.$axios
        .post(
          this.$store.state.root_url + "/vehicle/notes",
          {
            vin: vehicle.vin,
            notes: notes,
          },
          {
            params: { auth: true },
          }
        )
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    updateReservationNotes: function (id, notes) {
      this.$axios
        .post(
          this.$store.state.root_url + "/reservation/notes",
          {
            id: id,
            notes: notes,
          },
          {
            params: { auth: true },
          }
        )
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    updateLocation: function (vehicle, location) {
      this.$axios
        .post(
          this.$store.state.root_url + "/vehicle/location",
          {
            vin: vehicle.vin,
            location: location,
          },
          {
            params: { auth: true },
          }
        )
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    updateStatus: function (vehicle, status) {
      this.$axios
        .post(
          this.$store.state.root_url + "/vehicle/status",
          {
            vin: vehicle.vin,
            status: status,
          },
          {
            params: { auth: true },
          }
        )
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    locations(vehicle) {
      return this.$store.state.user.locations.filter(
        (location) =>
          location.active === true && location.region_id == vehicle.region_id
      );
    },
    getCurrentReservation(vehicle) {
      const now = new Date(); // Represents the current time
      const twoWeeksAgo = new Date(now.getTime() - 14 * 24 * 60 * 60 * 1000); // 2 weeks ago from current time

      // Filter out cancelled and returned reservations and sort by pickup time
      let vehicleReservations = this.reservations
        .filter(
          (reservation) =>
            reservation.vehicle_vin === vehicle.vin &&
            reservation.status !== "Canceled" &&
            reservation.status !== "Returned"
        )
        // eslint-disable-next-line
        .sort((a, b) => new Date(a.pickup_at) - new Date(b.pickup_at));

      // Find all "On Rent" reservations
      const onRentReservations = vehicleReservations.filter(
        (reservation) => reservation.status === "On Rent"
      );

      // Determine the current reservation
      let currentReservation = null;
      if (onRentReservations.length === 1) {
        currentReservation = onRentReservations[0];
      } else if (onRentReservations.length > 1) {
        // Sort by oldest return date and select the first
        currentReservation = onRentReservations.sort(
          (a, b) => new Date(a.dropoff_at) - new Date(b.dropoff_at)
        )[0];
      }

      // Remove the current reservation from the list if it exists
      if (currentReservation) {
        vehicleReservations = vehicleReservations.filter(
          (reservation) => reservation !== currentReservation
        );
      }

      // Sort remaining reservations by the closest upcoming date
      vehicleReservations = vehicleReservations
        .filter(
          (reservation) =>
            reservation.status === "Pending" || reservation.status === "On Rent"
        )
        // eslint-disable-next-line
        .sort((a, b) => new Date(a.pickup_at) - now);

      // Find the next reservation
      const nextReservation =
        vehicleReservations.length > 0 ? vehicleReservations[0] : null;

      // Find the most recent reservation that has returned within the last two weeks
      let recentReservation =
        this.reservations
          .filter(
            (reservation) =>
              reservation.vehicle_vin === vehicle.vin &&
              reservation.status === "Returned" &&
              new Date(reservation.return_at) >= twoWeeksAgo &&
              new Date(reservation.return_at) <= now
          )
          // Sort them by dropoff date, most recent first
          .sort((a, b) => new Date(b.return_at) - new Date(a.return_at))[0] ||
        null; // Take the most recent or null if none

      // Return the entire reservation objects or null
      return {
        current: currentReservation || null,
        next: nextReservation,
        recent: recentReservation,
      };
    },
    copyToClipboard(text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          // Optional: Display a message or perform an action to inform the user that the text has been copied
          console.log("Copied to clipboard:", text);
        })
        .catch((err) => {
          console.error("Error in copying text: ", err);
        });
    },
  },
  computed: {
    vehicles() {
      return this.$store.state.user.vehicles;
    },
    reservations() {
      return this.$store.state.user.reservations;
    },
    sortedVehicles: function () {
      //Make it reactive to reservations changes
      // eslint-disable-next-line
      const reservations = this.reservations;

      let filteredVehicles = this.vehicles;

      // Step 1a: Filter based on status
      if (this.status !== "") {
        filteredVehicles = this.vehicles.filter((vehicle) => {
          return vehicle.status === this.status;
        });
      }
      // Step 1b: Filter based on region
      if (this.region !== "") {
        filteredVehicles = filteredVehicles.filter((vehicle) => {
          return vehicle.region_id === this.region;
        });
      }
      // Step 1c: Filter based on location
      if (this.location !== "") {
        filteredVehicles = filteredVehicles.filter((vehicle) => {
          return vehicle.location_id === this.location;
        });
      }

      // Step 2: Apply search filter to the result
      const searchTerms = this.searchQuery.toLowerCase().trim().split(/\s+/);
      const vehicleMatches = filteredVehicles.filter((vehicle) => {
        const vin = vehicle.vin.toLowerCase();
        const model = vehicle.model.toLowerCase();
        const licensePlate = vehicle.license_plate
          ? vehicle.license_plate.toLowerCase()
          : "";

        return searchTerms.some(
          (term) =>
            vin.includes(term) ||
            model.includes(term) ||
            licensePlate.includes(term)
        );
      });

      const reservationVehicleVins = this.reservations
        .filter((reservation) => {
          return searchTerms.some((term) => {
            const firstName = reservation.customer_first_name.toLowerCase();
            const lastName = reservation.customer_last_name.toLowerCase();
            const id = reservation.id.toString().toLowerCase();
            const platformId = reservation.platform_id.toLowerCase();
            const phone = reservation.phone.toLowerCase();
            const vehicleModel = reservation.vehicle_model.toLowerCase();

            return (
              firstName.includes(term) ||
              lastName.includes(term) ||
              id.includes(term) ||
              platformId.includes(term) ||
              phone.includes(term) ||
              vehicleModel.includes(term)
            );
          });
        })
        .map((reservation) => reservation.vehicle_vin)
        .filter((vin) =>
          filteredVehicles.some((vehicle) => vehicle.vin === vin)
        );

      const combinedVehicleVins = [
        ...new Set([
          ...vehicleMatches.map((v) => v.vin),
          ...reservationVehicleVins,
        ]),
      ];

      return this.lodash.orderBy(
        this.vehicles.filter((vehicle) =>
          combinedVehicleVins.includes(vehicle.vin)
        ),
        [this.sorted],
        [this.order]
      );
    },
  },
};
</script>

<style scoped>
.vehicle-card {
  min-width: 250px !important;
}

.table-responsive {
  overflow-x: auto;
}

.reservation-indicator {
  /* Styles for indicating a reservation on a specific day */
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

th,
td {
  white-space: nowrap !important; /* Prevent wrapping in table headers */
}
@media (max-width: 767.98px) {
  #reservationOffcanvas-vehicles {
    width: 100% !important;
  }
}
#reservationOffcanvas-vehicles {
  min-width: 75% !important;
}
thead th {
  position: sticky;
  top: 0;
  background-color: white; /* To ensure the header is not transparent */
  z-index: 1; /* To keep the header above other content */
}
.clickable {
  cursor: pointer;
}
.color-dot {
  width: 0.75em;
  height: 0.75em;
  border-radius: 50%;
  display: inline-block;
  border: black 1px solid;
  vertical-align: middle !important;
}
</style>
